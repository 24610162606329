import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react"
import { Linking, Platform, Text, View } from "react-native"
import { ShareLinkBar } from "@components/Drawer/content/ShareVoteModal/ShareLinkBar"
import { PollContext } from "@contexts/PollContext"
import { UserContext } from "@contexts/UserDataProvider"
import { useScreenDimensions } from "@helpers/hooks/useScreenDimensions"
import useStyles from "@helpers/hooks/useStyles"
import { sendPollViaSms } from "@services/poll/sendViaSms"
import * as Clipboard from "expo-clipboard"
import {
    FormError,
    ImageWithText,
    LottieAnimation,
    StatusButton,
    Title,
} from "@components"
import { useTranslation } from "react-i18next"
import { isValidPhoneNumber as checkIsValidPhoneNumber } from "react-phone-number-input"
import PhoneInput, {
    Value as PhoneNumberType,
} from "react-phone-number-input/react-native-input"
import { sharePollModalStyles } from "./SharePollModal.styles"
import {
    AnalyticsActionType,
    AnalyticsContext,
    AppContext,
    ScreenShotContext,
} from "@contexts"
import { captureMessage } from "@services/sentry"
import { AnalyticsEvent } from "@services/analytics"
import { isPlatformAndroid } from "@helpers/isPlatformAndroid"
import { isAndroid as isAndroidWeb } from "react-device-detect"
import {
    EmbeddedMessageEvent,
    sendWrapperMessage,
} from "@helpers/sendWrapperMessage"
import { EmbeddedState } from "@contexts/AppProvider/AppReducer"
import QRCode from "react-native-qrcode-svg"

const SharePollModal = () => {
    const { isMobileView, isSdkWebDesktop } = useScreenDimensions()
    const { t } = useTranslation()
    const { styles, theme } = useStyles(
        sharePollModalStyles,
        isSdkWebDesktop ? "WEB" : undefined,
    )
    const {
        pollState: { pollId },
    } = useContext(PollContext)
    const {
        state: { shareLink },
    } = useContext(ScreenShotContext)
    const {
        userState: { deviceId, phoneNumber: userPhoneNumber },
    } = useContext(UserContext)
    const { analyticsDispatch } = useContext(AnalyticsContext)
    const {
        appState: { embeddedState },
    } = useContext(AppContext)

    const [phoneNumber, setPhoneNumber] = useState<PhoneNumberType>("")
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)
    const [isSendSmsLoading, setIsSmsLoading] = useState(false)
    const [smsError, setSmsError] = useState("")
    const [openAppError, setOpenAppError] = useState("")
    const [smsSuccess, setSmsSuccess] = useState(false)

    const isMobile = useMemo(() => {
        return isMobileView || Platform.OS !== "web"
    }, [isMobileView, Platform.OS])

    const openLink = useCallback(
        async (url: string) => {
            const openLinkHelper = async (url: string) => {
                try {
                    await Linking.openURL(url)
                } catch (e) {
                    captureMessage(e)
                    setOpenAppError(t("sharePoll.appError"))
                }
            }

            const sendWrapperMessageHelper = (url: string) => {
                sendWrapperMessage({
                    event: EmbeddedMessageEvent.OPEN_LINK,
                    payload: {
                        url: url,
                        newTab: true,
                    },
                })
            }

            if (embeddedState) {
                switch (embeddedState) {
                    case EmbeddedState.SDK_WEB_DESKTOP: {
                        sendWrapperMessageHelper(url)
                        break
                    }
                    case EmbeddedState.SDK_WEB_MOBILE: {
                        sendWrapperMessageHelper(url)
                        break
                    }
                    default: {
                        await openLinkHelper(url)
                        return
                    }
                }
            } else {
                await openLinkHelper(url)
            }
        },
        [embeddedState, t],
    )

    const handlePress = useCallback(() => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapSharePollCopy,
            },
        })
        Clipboard.setString(shareLink || "")
    }, [shareLink])

    const handleEmailPress = useCallback(async () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapSharePollEmail,
            },
        })
        await openLink(`mailto:?body=${encodeURIComponent(shareLink)}`)
    }, [shareLink])

    const handleMessagePress = useCallback(async () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapSharePollSms,
            },
        })
        // iOS & desktop platforms handle SMS deep linking differently than android
        // so we need a special case for Native Android / Android Web
        const separatorHandler = isAndroidWeb || isPlatformAndroid() ? "?" : "&"
        await openLink(
            `sms:${separatorHandler}body=${encodeURIComponent(shareLink)}`,
        )
    }, [shareLink, isAndroidWeb, isPlatformAndroid])

    const handleWhatsappPress = useCallback(async () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapSharePollWhatsApp,
            },
        })
        await openLink(
            `https://api.whatsapp.com/send?phone=&text=${encodeURIComponent(
                shareLink,
            )}`,
        )
    }, [shareLink])

    const handleMessengerPress = useCallback(async () => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapSharePollMessenger,
            },
        })
        await openLink(
            `fb-messenger://share?link=${encodeURIComponent(shareLink)}`,
        )
    }, [shareLink])

    const handlePhoneInputChange = useCallback(
        (newPhoneNumber: PhoneNumberType) => {
            const phoneNumber = newPhoneNumber ?? ""
            setPhoneNumber(phoneNumber)
            setIsValidPhoneNumber(checkIsValidPhoneNumber(phoneNumber))
        },
        [],
    )

    const handleSendSms = useCallback(() => {
        analyticsDispatch({
            type: AnalyticsActionType.LOG_ANALYTIC,
            payload: {
                analyticId: AnalyticsEvent.tapSharePollTextMe,
            },
        })
        setIsSmsLoading(true)
        sendPollViaSms({
            deviceId: deviceId,
            pollId: pollId,
            phoneNumber: `${phoneNumber}`,
            setIsSmsLoading,
            setSmsSuccess,
            setSmsError,
            t,
        })
    }, [deviceId, pollId, phoneNumber])

    useEffect(() => {
        if (userPhoneNumber) handlePhoneInputChange(userPhoneNumber)
    }, [userPhoneNumber])

    let showMessenger = isMobile
    if (embeddedState && embeddedState !== EmbeddedState.SDK_WEB_MOBILE) {
        showMessenger = false
    }

    return (
        <View style={styles.content}>
            <Title
                title={
                    !isMobile
                        ? t("sharePoll.webTitle")
                        : t("sharePoll.mobileTitle")
                }
                style={styles.title}
            />
            {isMobile && (
                <Text style={styles.subtitle}>
                    {t("sharePoll.mobileSubtitle")}
                </Text>
            )}
            {shareLink.length > 0 ? (
                <>
                    <View style={styles.iconsWrapper}>
                        <ImageWithText
                            source={
                                Platform.OS === "ios"
                                    ? theme.image.shareViaIMessages
                                    : theme.image.shareViaSMS
                            }
                            title={
                                Platform.OS === "ios"
                                    ? t("sharePoll.imessage")
                                    : t("sharePoll.sms")
                            }
                            onPress={handleMessagePress}
                        />
                        <ImageWithText
                            source={theme.image.shareViaEmail}
                            title={t("sharePoll.email")}
                            onPress={handleEmailPress}
                        />
                        <ImageWithText
                            source={theme.image.shareViaWhatsApp}
                            title={t("sharePoll.whatsapp")}
                            onPress={handleWhatsappPress}
                        />
                        {showMessenger && (
                            <ImageWithText
                                source={theme.image.shareViaMessenger}
                                title={t("sharePoll.messenger")}
                                onPress={handleMessengerPress}
                            />
                        )}
                    </View>
                    {!!openAppError && (
                        <FormError
                            message={openAppError}
                            setErrorMessage={setOpenAppError}
                        />
                    )}
                    <View style={styles.shareLinkWrapper}>
                        <ShareLinkBar
                            onPress={handlePress}
                            shareLink={shareLink}
                        />
                    </View>
                    {(!isMobile || isSdkWebDesktop) && (
                        <>
                            <View style={styles.scanContainer}>
                                <View style={styles.scanTextContainer}>
                                    <Title
                                        style={styles.scanTitle}
                                        title={t("sharePoll.openPollOnPhone")}
                                    />
                                    <Text style={styles.scanDescription}>
                                        {t("sharePoll.scanToOpenPoll")}
                                    </Text>
                                </View>
                                <QRCode
                                    value={shareLink}
                                    size={80}
                                    backgroundColor={
                                        theme.color.shareQrCodeBackground
                                    }
                                    color={theme.color.shareQrCodeForeground}
                                />
                            </View>

                            {/* disabling text me the link due to SMS attack
                            replacing with QR code for now */}

                            {/* <Title
                                title={t("sharePoll.openPollOnPhone")}
                                style={styles.phoneTitle}
                            />

                            <View style={styles.phoneInputWrapper}>
                                <PhoneInput
                                    disabled={isSendSmsLoading}
                                    placeholder={t(
                                        "phoneNumberModal.phoneNumberPlaceholder",
                                    )}
                                    value={phoneNumber}
                                    onChange={handlePhoneInputChange}
                                    defaultCountry="US"
                                    style={styles.phoneInput}
                                />
                                <StatusButton
                                    title={t("send")}
                                    completedTitle={t("sent")}
                                    onPress={handleSendSms}
                                    complete={smsSuccess}
                                    isLoading={isSendSmsLoading}
                                    disabled={!isValidPhoneNumber || smsSuccess}
                                />
                            </View>
                            {!!smsError && (
                                <FormError
                                    message={smsError}
                                    setErrorMessage={setSmsError}
                                />
                            )} */}
                        </>
                    )}
                </>
            ) : (
                <View style={styles.spinnerWrapper}>
                    <View style={styles.spinnerContainer}>
                        <LottieAnimation
                            source={theme.lottie.spinner}
                            style={styles.spinner}
                            autoPlay
                            loop
                        />
                    </View>
                </View>
            )}
        </View>
    )
}

export default SharePollModal
